<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-card>
      <v-card-title class="card-title">
        <h4>{{ $t('orders') }}</h4>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :loading="loading"
        :headers="mainHeaders"
        :items="filteredOrders"
        :items-per-page="filteredOrders.length"
        :footer-props="footerProps"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        dense
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        item-key="Id"
        fluid
        fixed-header
        style="cursor:pointer"
        class="elevation-1"
        @click:row="expand"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-spacer />
            <v-row>
              <v-col
                cols="12"
                lg="3"
                md="3"
                sm="3"
                offset="8"
              >
                <v-select
                  v-if="!loading"
                  v-model="selectedStatus"
                  :loading="loading"
                  :items="orderStatuses"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  dense
                  multiple
                  placeholder="All"
                  class="mt-6"
                />
              </v-col>
            </v-row>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            />
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                @click="processOrder(item)"
                v-on="on"
              >
                mdi-forward
              </v-icon>
            </template>
            <span>{{ $t('processOrder') }}</span>
          </v-tooltip>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                @click="acceptOrder(item)"
                v-on="on"
              >
                mdi-check
              </v-icon>
            </template>
            <span>{{ $t('acceptOrder') }}</span>
          </v-tooltip> -->
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <order-details-card
              :item="item"
              :detailed-order="detailedOrder"
            />
          </td>
        </template>
      </v-data-table>
      <v-dialog
        v-model="processDialog"
        width="500"
        dense
      >
        <v-card>
          <v-card-title
            class="pa-3 color: primary"
            dense
          >
            <h5 class="white--text">
              {{ $t('processOrder') }}
            </h5>
            <v-spacer />

            <v-icon
              color="white"
              aria-label="Close"
              @click="closeProcessDialog"
            >
              mdi-close
            </v-icon>
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-model="currentStatus"
              :headers="processHeaders"
              hide-default-footer
              :items="filteredOrderStatuses"
              :single-select="true"
              item-value="LowValue"
              item-key="LowValue"
              show-select
              class="elevation-1"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              class="primary"
              width="110px"
              @click="setStatus()"
            >
              {{ $t('processOrder') }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import OrderDetailsCard from '../Cards/OrderDetailsCard.vue'

  export default {
    components: {
      OrderDetailsCard,
    },
    data: () => ({
      sortBy: 'Id',
      processDialog: false,
      currentStatus: [],
      sortDesc: true,
      loading: true,
      search: '',
      currentOrder: 0,
      dialogDetails: false,
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      orderToBeDelete: -1,
      selectedStatus: [],
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      orders: [],
      selectedOrder: {},
      expanded: [],
    }),
    computed: {
      ...mapState({
        ordersFromRepo: (state) => state.order.orders,
        detailedOrder: (state) => state.order.selectedOrder,
        orderStatuses: (entytiStatuses) => entytiStatuses.verifier.orderStatuses,
        userData: (state) => state.authentication.userData,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      mainHeaders () {
        let headers = []
        if (this.userData.roleData.roleName === 'BRANCH_ADMIN' || this.userData.roleData.roleName === 'BRANCH_STAFF') {
          headers = [
            { text: this.$t('Id'), value: 'Id', align: 'start' },
            { text: this.$t('date'), value: 'date' },
            { text: this.$t('time'), value: 'time' },
            { text: this.$t('name'), value: 'consumerData.consumerName' },
            { text: this.$t('phone'), value: 'consumerData.contactPhone' },
            { text: this.$t('email'), value: 'consumerData.contactEmail' },
            { text: this.$t('orderCode'), value: 'orderCode' },
            { text: this.$t('status'), value: 'statusForDisplay' },
            { text: this.$t('totalOrderPrice'), value: 'totalOrderPrice' },
            { text: this.$t('actions'), value: 'actions' },
          ]
        } else {
          headers = [
            { text: this.$t('Id'), value: 'Id', align: 'start' },
            { text: this.$t('date'), value: 'date' },
            { text: this.$t('time'), value: 'time' },
            { text: this.$t('name'), value: 'consumerData.consumerName' },
            { text: this.$t('phone'), value: 'consumerData.contactPhone' },
            { text: this.$t('email'), value: 'consumerData.contactEmail' },
            { text: this.$t('orderCode'), value: 'orderCode' },
            { text: this.$t('status'), value: 'statusForDisplay' },
            { text: this.$t('totalOrderPrice'), value: 'totalOrderPrice' },
          ]
        }
        return headers
      },
      processHeaders () {
        return [
          { text: this.$t('status'), value: 'ShortDescription' },
        ]
      },
      filteredOrderStatuses () {
        let filteredOrderStatuses = {}
        filteredOrderStatuses = this.orderStatuses.filter(o => o.LowValue === 2 || o.LowValue === 3)
        return filteredOrderStatuses
      },
      filteredOrders () {
        // var orders = []
        // this.orders.forEach(() => {
        //   if (this.selectedStatus.length === 0) {
        //     orders = this.orders.filter(order => order.status === 1)
        //   } else {
        //     orders = this.orders.filter(order => this.selectedStatus.includes(order.status))
        //   }
        // })
        return this.orders
      },
    },
    async mounted () {
      await this.getOrders()
    },
    methods: {
      addClick () {
        this.$nextTick(() => {
          this.dialog = true
        })
      },
      async getOrders () {
        await this.$store.dispatch('order/getOrders')
          .then(() => {
            this.orders = this.ordersFromRepo.map(c => {
              return {
                Id: c.Id,
                date: c.OrderTimestamp.substr(0, 10),
                time: c.OrderTimestamp.substring(c.OrderTimestamp.lastIndexOf('T') + 1, c.OrderTimestamp.lastIndexOf('.')),
                consumerId: c.ConsumerId,
                consumerUserId: c.ConsumerUserId,
                contractId: c.ContractId,
                customerId: c.CustomerId,
                dealerId: c.DealerId,
                orderCode: c.OrderCode,
                status: c.Status,
                statusId: c.StatusId,
                totalOrderPrice: c.TotalOrderPrice,
                warehouseId: c.WarehouseId,
                consumerData: c.ConsumerData,
                statusForDisplay: this.getVerifierShortDescription(this.orderStatuses, c.Status),
              }
            })
            this.loading = false
          })
      },
      setStatus () {
        const body = {
          OrderId: this.currentOrder.Id,
          statusValueToSet: this.currentStatus[0].LowValue,
        }
        this.$store.dispatch('order/setOrderStatusById', body).then(() => {
          this.closeProcessDialog()
        })
      },
      processOrder (item) {
        this.expanded[0] = item
        this.currentOrder = item
        this.processDialog = true
      },
      dialogClosed () {
        this.dialogEdit = false
        this.dialog = false
        this.initialize()
      },
      closeDelete () {
        this.orderToBeDelete = -1
        this.dialogDelete = false
      },
      async closeProcessDialog () {
        this.processDialog = false
        this.loading = true
        await this.getOrders()
      },
      deleteOrderConfirm () {
        this.$store.dispatch('order/deleteOrder', this.orderToBeDelete)
          .then(res => {
            if (res.resFlag) {
              this.dialogDelete = false
              this.initialize()
            }
          })
      },
      async expand (item) {
        if (item === this.expanded[0]) this.expanded = []
        else this.expanded = [item]
      },
    },
  }
</script>

<style lang="scss">
</style>
