<template>
  <div>
    <v-progress-linear
      v-if="!loading"
      indeterminate
    />
    <v-row
      v-if="loading"
      dense
    >
      <v-col
        cols="12"
        :lg="deliveryData !== null ? 4 : 5"
        :md="deliveryData !== null ? 4 : 5"
        :sm="deliveryData !== null ? 4 : 5"
      >
        <v-card
          rounded="lg"
          hover
          :height="300"
          width="100%"
        >
          <v-card-title
            class="canvas"
            style="padding: 0px 15px; height: 40px;"
          >
            <v-col style="padding: 5px 5px; height: 40px;">
              <v-badge
                dot
                inline
                left
              />
              <span style="font-size: 15px">Sender</span>
            </v-col>
            <v-col style="padding: 5px; height: 40px;">
              <v-badge
                dot
                inline
                left
              />
              <span style="font-size: 15px">Receiver</span>
            </v-col>
          </v-card-title>
          <v-list
            dense
            :height="250"
            class="overflow-y-auto"
          >
            <v-list-item three-line>
              <v-list-item-content v-if="orderDetails && orderDetails.CustomerData && orderDetails.ConsumerData && orderDetails.CustomerData.addressData && deliveryLocation">
                <v-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <v-row>
                    <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-2" /> {{ $t('name') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.CustomerData.contactPerson }}</span></v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-2" /> {{ $t('phone') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.CustomerData.contactPhone }}</span></v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-2" /> {{ $t('email') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.CustomerData.contactEmail }}</span></v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon class="mdi mdi-home-map-marker px-2 py-2" /> {{ $t('addressToDisplay') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.CustomerData.addressData.addressLine }}</span></v-list-item-subtitle>
                    <v-list-item-subtitle><v-icon class="mdi mdi-city px-2 py-2" /> {{ $t('city') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.CustomerData.addressData.city }}</span></v-list-item-subtitle>
                  </v-row>
                </v-col>
                <v-col
                  lg="6"
                  md="6"
                  sm="12"
                >
                  <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-2" /> {{ $t('name') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.ConsumerData.contactPerson }}</span></v-list-item-subtitle>
                  <v-list-item-subtitle><v-icon class="mdi mdi-phone px-2 py-2" /> {{ $t('phone') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.ConsumerData.contactPhone }}</span></v-list-item-subtitle>
                  <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-2" /> {{ $t('email') }}: <span class="mt-2 font-weight-bold">{{ orderDetails.ConsumerData.contactEmail }}</span></v-list-item-subtitle>
                  <v-list-item-subtitle><v-icon class="mdi mdi-home-map-marker px-2 py-2" /> {{ $t('addressToDisplay') }}: <span class="mt-2 font-weight-bold">{{ deliveryLocation.addressLine }}</span></v-list-item-subtitle>
                  <v-list-item-subtitle><v-icon class="mdi mdi-city px-2 py-2" /> {{ $t('city') }}: <span class="mt-2 font-weight-bold">{{ deliveryLocation.city }}</span></v-list-item-subtitle>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        v-if="deliveryData !== null"
        cols="12"
        :lg="2"
        :md="2"
        :sm="2"
      >
        <v-card
          rounded="lg"
          hover
          :height="300"
          width="100%"
        >
          <v-card-title
            class="canvas"
            style="padding: 5px 20px; height: 40px;"
          >
            <v-badge
              dot
              inline
              left
            />
            <span style="font-size: 15px">Delivery Details</span>
            <v-spacer />
          </v-card-title>
          <v-list
            dense
            :height="250"
            class="overflow-y-auto"
          >
            <v-list-item>
              <v-col>
                <v-list-item-subtitle><v-icon class="mdi mdi-moped px-2 py-2" /> {{ $t('deliveryId') }}: <span class="mt-2 font-weight-bold">{{ deliveryData.id }}</span></v-list-item-subtitle>
                <v-list-item-subtitle><v-icon class="mdi mdi-account px-2 py-2" /> {{ $t('driverName') }}: <span class="mt-2 font-weight-bold">{{ deliveryData.deliveryDriverData.firstName + ' ' + deliveryData.deliveryDriverData.lastName }}</span></v-list-item-subtitle>
                <v-list-item-subtitle><v-icon class="mdi mdi-email px-2 py-2" /> {{ $t('email') }}: <span class="mt-2 font-weight-bold">{{ deliveryData.deliveryDriverData.email }}</span></v-list-item-subtitle>
                <v-list-item-subtitle><v-icon class="mdi mdi-list-status px-2 py-2" /> {{ $t('status') }}: <span class="mt-2 font-weight-bold">{{ this.getVerifierShortDescription(this.deliveryStatuses, deliveryData.status) }}</span></v-list-item-subtitle>
                <v-list-item-subtitle><v-icon class="mdi mdi-list-status px-2 py-2" /> {{ $t('orderStatus') }}: <span class="mt-2 font-weight-bold">{{ this.getVerifierShortDescription(this.orderStatuses, orderDetails.Status) }}</span></v-list-item-subtitle>
              </v-col>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :lg="3"
        :md="3"
        :sm="3"
      >
        <v-card
          rounded="lg"
          hover
          :height="300"
          width="100%"
        >
          <v-card-title
            class="canvas"
            style="padding: 5px 20px; height: 40px;"
          >
            <v-badge
              dot
              inline
              left
            />
            <span style="font-size: 15px">Order Items</span>
            <v-spacer />
          </v-card-title>
          <v-list
            v-if="!loadingTable"
            dense
            :height="250"
            class="overflow-y-auto"
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-data-table
                  :loading="loadingTable"
                  :headers="orderDetailsHeaders"
                  :items="orderDetails.OrderDetailsList"
                  dense
                  fixed-header
                  hide-default-footer
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        :lg="deliveryData !== null ? 3 : 4"
        :md="deliveryData !== null ? 3 : 4"
        :sm="deliveryData !== null ? 3 : 4"
      >
        <v-card
          rounded="lg"
          hover
          :height="300"
          width="100%"
        >
          <v-card-title
            class="canvas"
            style="padding: 5px 20px; height: 40px;"
          >
            <v-badge
              dot
              inline
              left
            />
            <span style="font-size: 15px">Shipping address</span>
            <v-spacer />
          </v-card-title>
          <v-list
            dense
            :height="250"
            class="overflow-y-auto"
          >
            <v-list-item three-line>
              <v-list-item-content v-if="deliveryLocation && addressData">
                <template>
                  <l-map
                    ref="map"
                    :zoom="zoom"
                    :style="{ height: 210 +'px', width: '250px', zIndex: 0 }"
                    :center="{lat: addressData[0], lng: addressData[1]}"
                    @ready="onReady"
                  >
                    <l-tile-layer
                      v-for="tileProvider in tileProviders"
                      :key="tileProvider.name"
                      :name="tileProvider.name"
                      :visible="tileProvider.visible"
                      :url="tileProvider.url"
                      :attribution="tileProvider.attribution"
                      layer-type="base"
                    />
                    <l-control-scale
                      position="bottomleft"
                      :imperial="false"
                      :metric="true"
                    />
                    <l-marker
                      ref="marker"
                      :lat-lng="{lat: addressData[0], lng: addressData[1]}"
                      :draggable="false"
                    />
                    <l-marker
                      v-if="deliveryLocation.latitude"
                      ref="markerShippindAddress"
                      :lat-lng="{lat: deliveryLocation.latitude, lng: deliveryLocation.longitude}"
                      :draggable="false"
                    />
                    <l-polyline
                      :lat-lngs="polyline.latlngs"
                      :options="polyline.options"
                    />
                  <!-- <l-routing-machine :waypoints="waypoints" /> -->
                  </l-map>
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { LMap, LTileLayer, LControlScale, LMarker, LPolyline } from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'
  // import LRoutingMachine from './test.vue'

  export default {
    components: {
      LMap,
      LTileLayer,
      LControlScale,
      LMarker,
      LPolyline,
    },
    props: {
      item: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data: () => ({
      // waypoints: [
      //   { lat: 41.98671207056115, lng: 20.960796946474073 },
      //   { lat: 41.99819466557136, lng: 20.95231043748329 },
      // ],
      loading: false,
      deliveryData: null,
      loadingTable: true,
      zoom: 0,
      tileProviders: [
        {
          name: 'Google Map',
          visible: false,
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
          url: 'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
        },
        {
          name: 'Openstreet Map',
          visible: true,
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a>',
        },
      ],
    }),
    computed: {
      ...mapState({
        orderDetails: (state) => state.order.order,
        deliveryLocation: state => state.address.selectedAddress,
        deliveryStatuses: (entytiStatuses) => entytiStatuses.verifier.deliveryStatuses,
        orderStatuses: (entytiStatuses) => entytiStatuses.verifier.orderStatuses,
      }),
      ...mapGetters({
        getVerifierCustomerId: 'verifier/getVerifierCustomerId',
        getVerifierId: 'verifier/getVerifierId',
        getVerifierLongDescription: 'verifier/getVerifierLongDescription',
        getVerifierLowValue: 'verifier/getVerifierLowValue',
        getVerifierRvDomain: 'verifier/getVerifierRvDomain',
        getVerifierShortDescription: 'verifier/getVerifierShortDescription',
        getObjectVerifierById: 'verifier/getObjectVerifierById',
      }),
      orderDetailsHeaders () {
        return [
          { text: this.$t('Id'), value: 'id', align: 'start' },
          { text: this.$t('itemName'), value: 'itemData.itemName' },
          { text: this.$t('itemDescription'), value: 'itemData.itemDescription' },
          { text: this.$t('itemPricePerUnit'), value: 'itemData.itemPricePerUnit' },
        ]
      },
      addressData () {
        return [this.orderDetails.CustomerData.addressData.latitude, this.orderDetails.CustomerData.addressData.longitude]
      },
      polyline () {
        return {
          latlngs: [[this.orderDetails.CustomerData.addressData.latitude, this.orderDetails.CustomerData.addressData.longitude], [this.deliveryLocation.latitude, this.deliveryLocation.longitude]],
          options: {
            color: 'blue',
            weight: 3,
            opacity: 0.5,
            dashArray: '5, 5',
            dashOffset: '5',
            smoothFactor: 1,
          },
        }
      },
    },
    watch: {
      item () {
        this.init()
      },
    },
    async mounted () {
      await this.init()
    },
    methods: {
      async init () {
        this.loading = false
        await this.getDetails()
        await this.getShippingAddress()
        this.loading = true
      },
      async getDetails () {
        this.loadingTable = true
        await this.$store.dispatch('order/getOrderById', this.item.Id)
        this.deliveryData = this.orderDetails.DeliveryData
        this.loadingTable = false
      },
      async getShippingAddress () {
        this.loadingTable = true
        await this.$store.dispatch('address/getAddressById', this.orderDetails.ConsumerData.shippingAddressId)
        this.loadingTable = false
      },
      onReady () {
        this.$refs.map.mapObject.invalidateSize()

        // FIT BOUNDS ON MAP READY
        this.$refs.map.fitBounds([
          [this.addressData[0], this.addressData[1]],
          [this.deliveryLocation.latitude, this.deliveryLocation.longitude],
        ])
      },
    },
  }
</script>

<style>

</style>
