var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-1",attrs:{"fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',{staticClass:"card-title"},[_c('h4',[_vm._v(_vm._s(_vm.$t('orders')))]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.mainHeaders,"items":_vm.filteredOrders,"items-per-page":_vm.filteredOrders.length,"footer-props":_vm.footerProps,"search":_vm.search,"single-expand":true,"expanded":_vm.expanded,"dense":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"item-key":"Id","fluid":"","fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.expand},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3","md":"3","sm":"3","offset":"8"}},[(!_vm.loading)?_c('v-select',{staticClass:"mt-6",attrs:{"loading":_vm.loading,"items":_vm.orderStatuses,"item-text":"ShortDescription","item-value":"LowValue","dense":"","multiple":"","placeholder":"All"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}):_vm._e()],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.processOrder(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-forward ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('processOrder')))])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('order-details-card',{attrs:{"item":item,"detailed-order":_vm.detailedOrder}})],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500","dense":""},model:{value:(_vm.processDialog),callback:function ($$v) {_vm.processDialog=$$v},expression:"processDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-3 color: primary",attrs:{"dense":""}},[_c('h5',{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.$t('processOrder'))+" ")]),_c('v-spacer'),_c('v-icon',{attrs:{"color":"white","aria-label":"Close"},on:{"click":_vm.closeProcessDialog}},[_vm._v(" mdi-close ")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.processHeaders,"hide-default-footer":"","items":_vm.filteredOrderStatuses,"single-select":true,"item-value":"LowValue","item-key":"LowValue","show-select":""},model:{value:(_vm.currentStatus),callback:function ($$v) {_vm.currentStatus=$$v},expression:"currentStatus"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":"","width":"110px"},on:{"click":function($event){return _vm.setStatus()}}},[_vm._v(" "+_vm._s(_vm.$t('processOrder'))+" ")]),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }